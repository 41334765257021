<template>
  <div>
    <button
      v-if="!sent && !isLoading"
      type="button"
      class="text-sm mx-auto block text-center my-4 opacity-90 text-blue-100 underline"
      @click="resendValidationCode"
    >
      Haven't recieved a confirmation code? Click to resend.
    </button>
    <p
      class="w-full border border-white-500 px-5 py-3 text-white text-sm text-center opacity-50 rounded-lg my-4"
      v-else-if="!sent && isLoading"
    >
      Sending new validation code...
    </p>
    <p
      class="w-full border border-green-500 px-5 py-3 text-green-100 text-sm text-center rounded-lg my-4"
      v-else-if="!isLoading && sent"
    >
      New code has been resent. Check your e-mail.
    </p>

    <p v-if="errorMsg" class="w-full border border-red-500 px-5 py-3 text-red-100 text-sm text-center rounded-lg mt-5">
      {{ errorMsg }}
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sent: false,
      isLoading: false,
    };
  },
  computed: {
    email() {
      return this.$route.params.email;
    },
  },
  methods: {
    resendValidationCode() {
      const body = JSON.stringify({
        username: this.email,
      });

      this.isLoading = true;

      fetch("https://uks-rg-nxo-auth-app-dev.azurewebsites.net/auth/resend", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
      })
        .then((res) => res.text())
        .then((res) => {
          this.isLoading = false;

          if (res !== "OK") {
            console.log(res);
            throw JSON.parse(res);
          }

          this.sent = true;
        })
        .catch((err) => {
          console.log(err);
          this.errorMsg = err.message;
        });

      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
