<script>
import Logo from "@/components/Logo";

export default {
  components: {
    Logo,
  },
};
</script>

<template>
  <div class="max-w-7xl mx-auto pt-8 flex flex-col min-h-screen px-4">
    <nav class="flex w-full mb-auto text-sm">
      <router-link class="mt-6 mx-auto logo" to="/register"><Logo :width="200" /></router-link>
    </nav>
    <router-view />
    <footer class="py-5 border-t opacity-50 text-xs text-center font-light mt-auto">
      &#169; 2023 Enexo. All rights reserved.
      <br />
      <a
        class="underline text-blue-200"
        href="https://app.enexo.io/terms-and-conditions"
        target="_blank"
        rel="noopener noreferrer"
        >Terms</a
      >
      |
      <a
        class="underline text-blue-200"
        href="https://www.softcat.com/privacy-policy"
        target="_blank"
        rel="noopener noreferrer"
        >Privacy</a
      >
      |
      <a
        class="underline text-blue-200"
        href="https://app.enexo.io/acceptable-use-policy"
        target="_blank"
        rel="noopener noreferrer"
        >Acceptable Use</a
      >
    </footer>
  </div>
</template>

<style lang="scss">
nav a:not(.logo) {
  &:hover,
  &:focus {
    @apply border-b-2 border-aqua-500 outline-none;
  }
}
</style>
